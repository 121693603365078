var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Vue from "vue";
import SignInOrUpForm from "./SignInOrUpForm.vue";
import { getSingleQueryValue } from "../../utils/request";
import getUserHomepage from "../../router/getUserHomepage";
import createAbsoluteUrl from "../../router/createAbsoluteUrl";
import { mapState } from "vuex";
export default Vue.extend({
    components: {
        SignInOrUpForm: SignInOrUpForm,
    },
    computed: __assign({ showSignIn: function () {
            return this.$route.path === "/sign-in";
        } }, mapState(["currency"])),
    methods: {
        onTabChange: function (isNewToStudentGuru) {
            if (isNewToStudentGuru) {
                this.$router.push({ path: "/sign-up", query: this.$route.query });
            }
            else {
                this.$router.push({ path: "/sign-in", query: this.$route.query });
            }
        },
        onSignInComplete: function (_a) {
            var type = _a.type, language = _a.language;
            var nextPath = getSingleQueryValue(this.$route, "nextPath") || getUserHomepage(type);
            // Header bar not updating when use router.push
            // TODO: Currency preference for user?
            window.location.href = createAbsoluteUrl({
                path: nextPath,
                locale: language,
                currency: this.currency,
            });
        },
        onSignUpComplete: function () {
            // NOTE: Don't need to worry about signed up user's currency or language
            // preference since they wil be whatever the current one is
            this.$router.push("/");
        },
    },
    metaInfo: function () {
        return {
            title: this.$t("Sign in").toString(),
        };
    },
});
