import SignInForm from "./SignInForm.vue";
import SignUpForm from "./SignUpForm.vue";
import IsNewTabs from "../ui/IsNewTabs.vue";
import NotificationsMixin from "../ui/NotificationsMixin";
import mixins from "vue-typed-mixins";
export default mixins(NotificationsMixin).extend({
    components: {
        SignInForm: SignInForm,
        SignUpForm: SignUpForm,
        IsNewTabs: IsNewTabs,
    },
    props: {
        className: {
            type: String,
            default: undefined,
        },
        showSubmit: {
            type: Boolean,
            default: true,
        },
        showForgotPassword: {
            type: Boolean,
            default: true,
        },
        showSignIn: {
            type: Boolean,
            default: undefined,
        },
    },
    data: function () {
        return {
            isNewToStudentGuru: false,
        };
    },
    computed: {
        tabsValue: function () {
            if (this.showSignIn !== undefined) {
                return !this.showSignIn;
            }
            return this.isNewToStudentGuru;
        },
    },
    methods: {
        onTabChange: function (tabValue) {
            this.isNewToStudentGuru = tabValue;
            this.$emit("tab", tabValue);
        },
        submit: function () {
            if (this.isNewToStudentGuru && this.$refs.signUpForm) {
                this.$refs.signUpForm.submit();
                return;
            }
            if (!this.isNewToStudentGuru && this.$refs.signInForm) {
                this.$refs.signInForm.submit();
            }
        },
        onSignInComplete: function (result) {
            this.$emit("signin", result);
        },
        onSignUpComplete: function (result) {
            this.$emit("signup", result);
        },
    },
});
